import { FC, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

const Tooltip: FC<{
  title?: string
  placement?: 'top' | 'right' | 'bottom' | 'left'
  children?: any
  className?: string
  contentClassName?: string
}> = ({ children, title, placement, className }) => {
  if (!title) return children
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <>
      <div data-tip={title} className={`tooltip ${className || ''}`}>
        {children}
      </div>
      {isMounted && <ReactTooltip place={placement} />}
    </>
  )
}

export default Tooltip
