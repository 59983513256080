import Image from 'next/image'
import { FC } from 'react'
import {
  LINK_URL_CANDIDATES_CV,
  LINK_URL_LOGIN,
  LINK_URL_MY_ACCOUNT,
  LINK_URL_CANDIDATES_CHANGE_PASSWORD,
  LINK_URL_DOWNLOAD_APP,
  LINK_URL_REFERRAL,
  LINK_URL_HELP_CENTER,
  LINK_URL_CHAT_WITH_SUPPORT
} from '../../utillities/LinkURL'
import LinkWrapper from '../Link'
import SOLUTION from './assets/solution.svg'
import LOCK from './assets/lock.svg'
// import BELL from './assets/bell.svg'
import SUITCASE from './assets/suitcase.svg'
import REFERRALS from './assets/referrals.svg'
import MOBILE from './assets/mobile.svg'
import EXPORT from './assets/export.svg'
import QUESTION from './assets/question.svg'
import MESSAGE from './assets/message.svg'
import SELECT_ICON from './assets/select.svg'
import { useTranslation } from '../../hooks/useTranslation'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogout from '../../hooks/useLogout'
import AvatarDefault from '../AvatarDefault/AvatarDefault'
export const AVATAR_DEFAULT = '/img/avatar_default.png'
import Tooltip from '../base/Tooltip'

const L4JFramOver = (Component) => (props) =>
  props.l4j ? (
    <div className="d-flex justify-content-center">
      <div style={{ display: 'grid', gridTemplateColumns: 'auto', width: props.size }}>
        <style jsx global>{`
          .avatar-l4j {
            background-image: linear-gradient(to bottom, rgba(54, 186, 126, 0) 61%, #36ba7e 92%);
            border-radius: 9999px;
          }
        `}</style>

        <div style={{ gridColumn: 1, gridRow: 1 }}>
          <Component {...props} />
        </div>
        {props.l4j && (
          <div
            style={{
              gridColumn: 1,
              gridRow: 1,
              width: props.size,
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end'
            }}
            className="avatar-l4j">
            <div
              style={{
                fontSize: 8 * (props.size / 64),
                lineHeight: 'normal',
                right: props.size / 8,
                position: 'relative',
                textAlign: 'center'
              }}>
              #open to
            </div>
            <div
              style={{
                fontSize: 9 * (props.size / 64),
                lineHeight: 'normal',
                position: 'relative',
                textAlign: 'center',
                marginBottom: 1 / (props.size / 64 / 2)
              }}>
              WORK
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Component {...props} />
  )
export const CandidateAvatar = L4JFramOver(({ src, name, size = 40 }) => {
  return (
    <>
      {src ? (
        <div className="d-flex justify-content-center align-items-center avatar">
          <img className="avatar-user" src={src || AVATAR_DEFAULT} />
        </div>
      ) : (
        <AvatarDefault size={size} name={name} />
      )}
    </>
  )
})

const HeaderProfile: FC<{ profile?: any; router?: any }> = ({ router }) => {
  const { translate } = useTranslation()
  const [currentUser] = useCurrentUser()
  const logout = useLogout()
  return (
    <div className="d-flex dropdown-profile">
      <label className="box-dropdown">
        <CandidateAvatar
          l4j={!!currentUser?.desire_change_job && currentUser?.desire_to_change_job_status === 'active'}
          size={40}
          src={currentUser?.photo_url}
          name={currentUser?.full_name}
        />
      </label>

      <div className="sub-dropdown">
        <div className="box">
          <div className="account-menubar-info">
            <LinkWrapper href={LINK_URL_MY_ACCOUNT} as={LINK_URL_MY_ACCOUNT}>
              <a className="d-flex">
                <div className="account-avatar">
                  <CandidateAvatar
                    l4j={!!currentUser?.desire_change_job && currentUser?.desire_to_change_job_status === 'active'}
                    size={64}
                    src={currentUser?.photo_url}
                    name={currentUser?.full_name}
                  />
                </div>
                <div>
                  <div className="account-menubar__name">{currentUser?.full_name}</div>
                  <div className="account-menubar__job-title">{currentUser?.job_title}</div>
                  <div className="account-menubar__email">{currentUser?.email}</div>
                </div>
              </a>
            </LinkWrapper>
          </div>
          <span className="account-divider" />

          <div className="account-menubar-list">
            <LinkWrapper href={LINK_URL_CANDIDATES_CV} as={LINK_URL_CANDIDATES_CV}>
              <a className={`account-menubar__item ${router.pathname === LINK_URL_CANDIDATES_CV && 'active'}`}>
                <Image src={SOLUTION} alt="file-text" width={20} height={20} />
                <span>{translate('header:profile_cv')}</span>
              </a>
            </LinkWrapper>
          </div>
          <div className="account-menubar-list">
            <LinkWrapper href={LINK_URL_CANDIDATES_CV} as={LINK_URL_CANDIDATES_CV}>
              <a className={`account-menubar__item ${router.pathname === LINK_URL_CANDIDATES_CV && 'active'}`}>
                <Image src={SUITCASE} alt="suitcase" width={20} height={20} />
                <span>{translate('header:job_interest')}</span>
              </a>
            </LinkWrapper>
          </div>
          <div className="account-menubar-list">
            <LinkWrapper href={LINK_URL_REFERRAL} as={LINK_URL_REFERRAL}>
              <a className={`account-menubar__item ${router.pathname === LINK_URL_REFERRAL && 'active'}`}>
                <Image src={REFERRALS} alt="file-text" width={20} height={20} />
                <span>{translate('header:referral')}</span>
              </a>
            </LinkWrapper>
          </div>
          <div className="account-menubar-list">
            <LinkWrapper href={LINK_URL_CANDIDATES_CHANGE_PASSWORD} as={LINK_URL_CANDIDATES_CHANGE_PASSWORD}>
              <a className={`account-menubar__item ${router.pathname === LINK_URL_CANDIDATES_CHANGE_PASSWORD && 'active'}`}>
                <Image src={LOCK} alt="lock" width={20} height={20} />
                <span>{translate('header:change_password')}</span>
              </a>
            </LinkWrapper>
          </div>
          <span className="account-divider" />

          <div className="account-menubar-list">
            {/* <LinkWrapper href={LINK_URL_DOWNLOAD_APP} as={LINK_URL_DOWNLOAD_APP}> */}
            <div className={`account-menubar__item ${router.pathname === LINK_URL_DOWNLOAD_APP && 'active'}`}>
              <Image src={MOBILE} alt="mobile" width={20} height={20} />
              <div className="d-flex justify-content-between w-100">
                <span>
                  <a href={LINK_URL_DOWNLOAD_APP}>{translate('header:account_candidate_download_app')}</a>
                </span>
                <Tooltip title={translate('header:open_in_new_tab')}>
                  <a href={LINK_URL_DOWNLOAD_APP} target="blank">
                    <Image src={SELECT_ICON} alt="mobile" width={14} height={14} />
                  </a>
                </Tooltip>
              </div>
            </div>
            {/* </LinkWrapper> */}
          </div>
          <div className="account-menubar-list">
            {/* <LinkWrapper href={LINK_URL_HELP_CENTER} as={LINK_URL_HELP_CENTER}> */}
            <div className={`account-menubar__item ${router.pathname === LINK_URL_HELP_CENTER && 'active'}`}>
              <Image src={QUESTION} alt="mobile" width={20} height={20} />
              <div className="d-flex justify-content-between w-100">
                <span style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                  <a href={LINK_URL_HELP_CENTER}>{translate('header:help_center')}</a>&nbsp;|{' '}
                  <a style={{ color: '#1890ff' }} href="mailto:support@freec.asia" className="account-menubar-link">
                    support@freec.asia
                  </a>
                </span>
                <Tooltip title={translate('header:open_in_new_tab')}>
                  <a href={LINK_URL_HELP_CENTER} target="blank">
                    <Image src={SELECT_ICON} alt="mobile" width={14} height={14} />
                  </a>
                </Tooltip>
              </div>
            </div>
            {/* </LinkWrapper> */}
          </div>
          <div className="account-menubar-list">
            {/* <LinkWrapper href={LINK_URL_CHAT_WITH_SUPPORT} as={LINK_URL_CHAT_WITH_SUPPORT}> */}
            <div className={`account-menubar__item ${router.pathname === LINK_URL_CHAT_WITH_SUPPORT && 'active'}`}>
              <Image src={MESSAGE} alt="mobile" width={20} height={20} />
              <div className="d-flex justify-content-between w-100">
                <span>
                  <a href={LINK_URL_CHAT_WITH_SUPPORT}>{translate('header:chat_with_support')}</a>
                </span>
                <Tooltip title={translate('header:open_in_new_tab')}>
                  <a href={LINK_URL_CHAT_WITH_SUPPORT} target="blank">
                    <Image src={SELECT_ICON} alt="mobile" width={14} height={14} />
                  </a>
                </Tooltip>
              </div>
            </div>
            {/* </LinkWrapper> */}
          </div>
          <span className="account-divider" />

          <div className="account-menubar-list">
            <a
              onClick={() => {
                return logout().then(() => {
                  router.push(LINK_URL_LOGIN)
                })
              }}
              className={`account-menubar__item ${router.pathname === '/account/logout' && 'active'}`}>
              <Image src={EXPORT} alt="export" width={20} height={20} />
              <span>{translate('header:account_log_out')}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderProfile
